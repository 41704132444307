import React from 'react'

import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown'

import V1DropdownButton from './themes/v1/DropdownButton'
import V2DropdownButton from './themes/v2/DropdownButton'

import { kebabCase } from 'lodash'

export default class ShareButton extends React.Component {

  themedDropdownButtonComponent() {
    switch (this.props.theme) {
      case 1:
        return V1DropdownButton
      case 2:
        return V2DropdownButton
    }
  }

  currentUrlWithMediaId() {
    const pageLocation = window.location.origin
    const mediaId = this.props.media ? this.props.media.id : ''
    const pageId = this.props.pageId
    const sectionId = this.props.sectionId
    let mediaTitle = this.props.media ? this.props.media.title : ''
    mediaTitle = kebabCase(mediaTitle).slice(0, 100).replace(/-$/, '')

    return encodeURIComponent(`${pageLocation}/media/${pageId}-${sectionId}-${mediaId}/${mediaTitle}`)
  }

  render() {
    const DropdownButton = this.themedDropdownButtonComponent()
    const currentUrlWithMediaId = this.currentUrlWithMediaId()

    return (
      <Dropdown className="share">
        <DropdownTrigger className="share"><DropdownButton>Share</DropdownButton></DropdownTrigger>
        <DropdownContent>
          <ul className="media-attachment-popup share-popup">
            <li>
              <a href={`http://www.facebook.com/sharer.php?u=${currentUrlWithMediaId}`} target="_blank">Share on Facebook</a>
            </li>
            <li>
              <a href={`https://twitter.com/share?url=${currentUrlWithMediaId}`} target="_blank">Share on Twitter</a>
            </li>
            <li>
              <a href={`mailto:?Body=${currentUrlWithMediaId}`}>Email to a Friend</a>
            </li>
          </ul>
        </DropdownContent>
      </Dropdown>
    )
  }
}
