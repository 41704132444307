import React from 'react'
const { Fragment } = React
import ReactDOM from 'react-dom'

export default function MediaArchive ({children, theme, id}) {
  if (theme === 2) {
    // In the V2 media player, the Media Archive appears within what looks like
    // a whole separate adjacent section.  In order to create this appearance
    // with less complex CSS, render these elements outside the current
    // container using a React portal.
    return ReactDOM.createPortal(
      children,
      document.querySelector(`#media-archive-${id}`)
    )
  }
  return <Fragment>{children}</Fragment>
}
