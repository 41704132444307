import React from 'react'
import SelectCheckMultiple from '../../../lib/components/SelectCheckMultiple'

import { uniq, compact, map, sortBy } from 'lodash'

function mapValues(options) {
  return map(options, (option) => option.value)
}

export default class MediaFilterSelect extends React.Component {

  constructor(props) {
    super(props)

    this.calculateFilterObject = this.calculateFilterObject.bind(this)

    this.state ={
      date_string: null,
      title: null,
      speaker: null,
      series: null
    }
  }

  getOptions(attr) {
    if(attr === 'date_string') {
      return uniq(compact(map(this.props.media, attr)))
    }
    return uniq(compact(map(sortBy(this.props.media, [attr]), attr)))
  }

  renderDropdown(attr, label) {
    let options = this.getOptions(attr)
    options = options.map((o) => { return { value: o, label: o } })


    if(options.length > 0) {
      return(
        <SelectCheckMultiple
          name="form-field-name"
          value={this.state[attr]}
          placeholder={label}
          onChange={(evt) => this.calculateFilterObject(attr, mapValues(evt))}
          options={options}
        />
      )
    } else {
      return ''
    }
  }

  calculateFilterObject(attr, values) {
    const obj = {}
    obj[attr] = values

    this.setState(obj, () => {
      this.props.filterMedia({
        date_string: this.state.date_string,
        title: this.state.title,
        speaker: this.state.speaker,
        series: this.state.series
      })
    })
  }

  render() {
    return(
      <div className="media-select-container">
        <p className="media-select-header">Media Archive ({this.props.media.length})</p>

        <div className="media-select-input-container">
          { this.renderDropdown('date_string', 'Date') }
          { this.renderDropdown('series', 'Series') }
          { this.renderDropdown('speaker', 'Speaker') }
          { this.renderDropdown('title', 'Title') }
        </div>
      </div>
    )
  }
}
