import React from 'react'
import classNames from 'classnames'

export default class MediaItem extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      imageLoading: true,
      selected: false,
      isReady: false,
      error: false
    }

    this.handleOnLoad = this.handleOnLoad.bind(this)
    this.selectMedia = this.selectMedia.bind(this)
    this.handleError = this.handleError.bind(this)
  }

  componentDidMount() {
    this.downloadAndLoadImage()
  }

  componentDidUpdate(previousProps) {
    if (previousProps !== this.props || this.imagePending()) {
      this.downloadAndLoadImage()
    }
  }

  downloadAndLoadImage() {
    if (this.props.media.state !== 'finished') {
      return
    }

    const downloadingImage = new Image()
    downloadingImage.onload = () => {
      this.setState({ thumbnail: downloadingImage.src, imageLoading: false })
    }

    downloadingImage.src = this.props.media.thumbnails.medium
  }

  isError() {
    return this.props.media.state === 'failed' ||
      this.props.media.uploadStatus === 'failed'
  }

  renderError() {
    return(
      <div className="spinner-container">
        <div className="media-error-icon"></div>
      </div>
    )
  }

  imagePending() {
    return this.state.imageLoading ||
           (this.props.media.upload_type === 'clover' &&
            this.props.media.state !== 'finished')
  }

  renderImage() {
    return(
      <div>
        { this.imagePending() &&
          <div className="spinner-container">
            <div className="item-spinner"></div>
          </div>
        }

        <img style={{ display: this.imagePending() ? 'none' : 'block' }}
            src={this.imagePending() ? null : this.props.media.thumbnails.medium}
            alt={this.props.media.title}
            onLoad={this.handleOnLoad}
            onError={this.handleError}
            />
      </div>
    )
  }

  handleOnLoad() {
    this.setState({ imageLoading: false })
  }

  handleError() {
    this.setState({ thumbnail: null, imageLoading: true })
  }

  selectMedia() {
    this.props.selectMedia(this.props.media.id, true)
  }

  render() {
    const classNameForCard = classNames('media-item media-card', {
      selected: this.props.selectedMedia.id === this.props.media.id
    })

    return(
      <button data-id={this.props.media.id} className={classNameForCard} onClick={this.selectMedia}>
        <div className="card-image">
          <div className="card-image-aspect-helper">
            { this.isError() && this.renderError() }
            { !this.isError() && this.renderImage() }
          </div>
        </div>
        <div className="card-body">
          <div className="title-container">
            <header className="text-content item-title">{this.props.media.title}</header>
            <div className="text-content">{moment(this.props.media.date).format('MMMM D, YYYY')}</div>
          </div>
        </div>
      </button>
    )
  }
}
